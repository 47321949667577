import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/media';
import GatsbyImage from 'gatsby-image';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';

interface Author {
  picture?: any;
  name: string;
  LinkedinUrl: string;
}

interface Editor {
  picture?: any;
  name: string;
  linkedinUrl: string;
}

interface SocialConfig {
  url: string;
  title: string;
  image?: any;
}

interface Props {
  authors: [Author];
  editors: [Editor];
  socialConfig: SocialConfig;
  avatarImage: any;
  linkedinMapping: any;
}

// Styling
const DesktopAuthEditorRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media ${media.tabletPortrait} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const DesktopAuthColContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.6;
  min-height: 30px;
  flex-wrap: wrap;
  @media ${media.tabletPortrait} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
`;

const DesktopAuthCol = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${(props) => (props.alwaysHalf ? 0.4 : 1)};
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-right: 8px;
`;

const DesktopEditorColContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0.4;
  min-height: 30px;
`;

const DesktopAuthColRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  @media ${media.tabletPortrait} {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
`;

// This will be displayed only on mobile
const AuthorShareRowContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  @media ${media.phone} {
    justify-content: center;
    align-items: center;
  }
`;

const GatsbyAvatar = styled(GatsbyImage)`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  margin-right: 8px;
`;

const PersonName = styled.p`
  margin-bottom: 0;
  margin-right: 8px;
  font-size: 0.8rem;
  @media ${media.laptop} {
    font-size: 0.8rem;
  }
`;

const FormatSection = (props: any) => {
  const { data, label, colsPerRow, avatarImage = 'image', url, alwaysHalf = false } = props;
  if (data && data.length > 0) {
    //  Determine the number of rows
    const totalLength = data.length;
    const transformedData: any = [];
    for (let index = 0; index < totalLength; index++) {
      const newRowIndex = Math.floor(index / colsPerRow);
      if (transformedData[newRowIndex] !== undefined) {
        //  A row exist
        transformedData[newRowIndex].push(data[index]);
      } else {
        //  Does not exist need to push a new row
        transformedData[newRowIndex] = [data[index]];
      }
    }
    return (
      <>
        <DesktopAuthColRow>
          <p>{label}</p>
        </DesktopAuthColRow>
        {transformedData.map((row: any, rowIndex: number) => {
          return (
            <DesktopAuthColRow key={`${rowIndex}`}>
              {row.map((col: any, colIndex: number) => {
                return (
                  <DesktopAuthCol key={`${rowIndex}-${colIndex}`} alwaysHalf={alwaysHalf}>
                    <GatsbyAvatar
                      fluid={col[avatarImage].childImageSharp.fluid}
                      title={col.name}
                      alt={col.name}
                    />
                    <PersonName>{col.name}</PersonName>
                    <a
                      href={col[url]}
                      target="_blank"
                      rel="noopener"
                      title={`Linked in Profile - ${col.name}`}
                    >
                      <FaLinkedin size={18} color="#0072b1" />
                    </a>
                  </DesktopAuthCol>
                );
              })}
            </DesktopAuthColRow>
          );
        })}
      </>
    );
    //  Determine the cols per row
    //  Return
  }
  return <></>;
};

const AuthorEditorsShare = (props: Props) => {
  const { authors, editors, avatarImage, linkedinMapping } = props;
  return (
    <AuthorShareRowContainer>
      <DesktopAuthEditorRow>
        {authors && authors.length > 0 && (
          <DesktopAuthColContainer>
            <FormatSection
              data={authors}
              label="Author(s)"
              colsPerRow={2}
              avatarImage={avatarImage.author}
              url={linkedinMapping.author}
              alwaysHalf={true}
            />
          </DesktopAuthColContainer>
        )}
        {editors && editors.length > 0 && (
          <DesktopEditorColContainer>
            <FormatSection
              data={editors}
              label="Editor(s)"
              colsPerRow={2}
              avatarImage={avatarImage.editor}
              url={linkedinMapping.editor}
            />
          </DesktopEditorColContainer>
        )}
      </DesktopAuthEditorRow>
    </AuthorShareRowContainer>
  );
};

export default AuthorEditorsShare;
