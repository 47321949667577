import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
// @ts-ignore
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';

import Markdown from 'react-markdown';
// import { kebabCase } from 'lodash';
import {
  Content,
  Header,
  Layout,
  PrevNext,
  SectionTitle,
  SEO,
  Subline,
  Wrapper,
} from '../components';
import config from '../../config/SiteConfig';
import '../utils/prismjs-theme.css';
import PathContext from '../models/PathContext';
import Post from '../models/Post';
import moment from 'moment';
import ShareButtons from '../components/ShareButtons';
import AuthorEditorsShare from '../components/AuthorEditorsShare';
import { media } from '../utils/media';

const NonMobileOnly = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 2rem;
`;

const ImageContainer = styled.img`
  margin-bottom: 0;
  width: 30%;
  @media ${media.phone} {
    width: 90%;
  }
  @media ${media.tabletPortrait} {
    width: 60%;
  }
  @media ${media.tabletLandscape} {
    width: 50%;
  }
  @media ${media.laptop} {
    width: 40%;
  }
`;

const CPLogoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CPLogoText = styled.p`
  font-size: 1.9rem;
  margin-bottom: 2px;
  margin-left: 8px;
  display: inline;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
`;

const DesktopSocialColContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 0.5;
  min-height: 30px;
`;

interface Props {
  data: {
    strapiArticle: Post;
  };
  pathContext: PathContext;
}

export default (props: Props) => {
  const { prev, next } = props.pathContext;
  const post = props.data.strapiArticle;
  const articleAuthors = post.authors || [];
  const articleEditors = post.editors || [];

  const renderNonMobileAuthorsShareRow = () => {
    return (
      <AuthorEditorsShare
        authors={articleAuthors}
        editors={articleEditors}
        socialConfig={{
          url: post.slug,
          title: post.title,
        }}
        avatarImage={{ author: 'picture', editor: 'image' }}
        linkedinMapping={{
          author: 'LinkedinUrl',
          editor: 'linkedinUrl',
        }}
      />
    );
  };

  const renderAuthorsShareRow = () => {
    return (
      <>
        <NonMobileOnly>{renderNonMobileAuthorsShareRow()}</NonMobileOnly>
      </>
    );
  };
  // @ts-ignore
  return (
    <Layout>
      {post ? (
        <>
          <SEO postPath={post.slug} postNode={post} postSEO={true} />
          <Helmet title={`${post.title} | ${config.siteTitle}`} />
          <Header banner={config.defaultPostBg}>
            <Link to="/">
              <CPLogoRow>
                <ImageContainer src={config.siteLogoWhite} alt={config.siteTitle} height={75} />
              </CPLogoRow>
            </Link>
            <SectionTitle>{post.title}</SectionTitle>
            <Subline>{moment(post.publishedAt).format('DD MMM, YYYY')}</Subline>
          </Header>
          <Wrapper>
            <Content>
              <DesktopSocialColContainer>
                <ShareButtons title={post.title} slug={post.slug} />
              </DesktopSocialColContainer>
              <Markdown
                source={post.content}
                escapeHtml={false}
                transformImageUri={(uri) =>
                  uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
                }
              />
              {renderAuthorsShareRow()}
              <PrevNext prev={prev} next={next} />
            </Content>
          </Wrapper>
        </>
      ) : null}
    </Layout>
  );
};

export const postQuery = graphql`
  query($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      content
      createdAt(formatString: "dd/mm/yyyy")
      description
      publishedAt
      slug
      status
      strapiId
      title
      authors {
        name
        LinkedinUrl
        picture {
          childImageSharp {
            fluid(maxWidth: 75) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      editors {
        image {
          childImageSharp {
            fluid(maxWidth: 75) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        linkedinUrl
        name
      }
      image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
`;
