import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// @ts-ignore
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaTwitterSquare } from '@react-icons/all-files/fa/FaTwitterSquare';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';

const ShareButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ShareButtons = (props: any) => {
  return (
    <ShareButtonContainer>
      <FacebookShareButton
        url={`${process.env.SHARE_BASE_URL}/${props.slug}`}
        title={props.title}
        style={{
          padding: '8px',
        }}
      >
        <span className="icon">
          <FaFacebookF />
        </span>
      </FacebookShareButton>
      <TwitterShareButton
        url={`${process.env.SHARE_BASE_URL}/${props.slug}`}
        title={props.title}
        style={{
          padding: '8px',
        }}
      >
        <span className="icon">
          <FaTwitterSquare />
        </span>
      </TwitterShareButton>
      <InstapaperShareButton
        url={`${process.env.SHARE_BASE_URL}/${props.slug}`}
        title={props.title}
        style={{
          padding: '8px',
        }}
      >
        <span className="icon">
          <FaInstagram />
        </span>
      </InstapaperShareButton>
      <LinkedinShareButton
        url={`${process.env.SHARE_BASE_URL}/${props.slug}`}
        title={props.title}
        style={{
          padding: '8px',
        }}
      >
        <span className="icon">
          <FaLinkedin />
        </span>
      </LinkedinShareButton>
    </ShareButtonContainer>
  );
};

ShareButtons.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
};

export default ShareButtons;
